<template>
    <div class="sld_chat_left">
        <div class="top">
            <el-popover placement="bottom" :width="280" trigger="click">
                <template #reference>
                    <div class="info">
                        <img :src="adminInfo.admin_logo" alt="">
                        <div class="text">
                            <p>
                                <span>{{decodeURIComponent(adminName)}}</span>
                                <img src="@/assets/service/service_logo.png" alt="">
                            </p>
                            <div>
                                <span class="color-blue" v-if="workState === 1">{{ L['在线'] }}</span>
                                <span class="color-red" v-if="workState === 2">{{ L['忙碌'] }}</span>
                                <span class="color-yellow" v-if="workState === 3">{{ L['休息'] }}</span>
                                <span>（{{ countChat }}）</span>
                            </div>
                        </div>
                    </div>
                </template>
                <template #default>
                    <el-radio-group v-model="workState" @change="handleChangeStatus">
                        <el-radio :label="1">在线</el-radio>
                        <el-radio :label="2">忙碌</el-radio>
                        <el-radio :label="3">休息</el-radio>
                    </el-radio-group>
                </template>
            </el-popover>
            <div class="batch-box" v-if="chatList.list.length">
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                <el-popconfirm :title="L['确定移除已选择的联系人吗？']" @confirm="closeChatMemberAll"
                    :confirmButtonText="L['确定']" :cancelButtonText="L['取消']" v-if="contactIds.length">
                    <template #reference>
                        <div class="btn-delete-all">移除选择</div>
                    </template>
                </el-popconfirm>
            </div>
        </div>
        <div class="list_wrap">
            <div class="list_con">
                <el-scrollbar id="leftScroll">
                    <div v-if="tabIndex==1&&chatList.list.length" v-infinite-scroll="load"
                        :infinite-scroll-distance="10">
                        <el-checkbox-group v-model="contactIds" size="medium" @change="handleCheckChange">
                        <div class="list_item"
                            :class="{'active': item.memberId == curMemberId}"
                            :style="{'background-color':item.memberId == curMemberId?'#F5F5F5':'#fff'}"
                            v-for="(item) in chatList.list" :key="item.memberId" id="processing"
                            @click="switchMember(item.memberId, item.memberName, item.memberRemarks)" @touchend="switchMember(item.memberId, item.memberName, item.memberRemarks)">
                            <el-checkbox class="checkbox" :label="item.memberId" @click="handleCheckbox">.</el-checkbox>
                            <img :src="item.memberAvatar">
                            <i class="msg_badge" v-if="item.receiveMsgNumber">{{item.receiveMsgNumber>99?'99+':item.receiveMsgNumber}}</i>
                            <div class="list_text">
                                <div class="list_item_title">
                                    <span>{{ item.memberName }}</span>
                                    <span class="msg_time">{{ formatTime(item.createTime, 1) }}</span>
                                </div>
                                <div class="msg_new">
                                    <span class="msg_content">{{item.showContent}}</span>
                                    <span class="msg_unread" v-if="!([2, 3].includes(item.userType) && item.adminId == connectData.userId)">🔔</span>
                                </div>
                            </div>
                            <div class="delete_icon">
                                <div class="del_icon">
                                    <el-popconfirm :title="L['确定结束与该会员的聊天吗？']" @confirm="closeChatMember(item.memberId)"
                                        :confirmButtonText="L['确定']" :cancelButtonText="L['取消']">
                                        <template #reference>
                                            <img src="@/assets/service/delete.png" alt="">
                                        </template>
                                    </el-popconfirm>
                                </div>
                                <img @click="(event) => handleToggleTop(item, event)" class="btn-top show" src="@/assets/top2.png" v-if="item.isTopping === 1">
                                <img @click="(event) => handleToggleTop(item, event)" class="btn-top" src="@/assets/top.png" v-else>
                            </div>
                        </div>
                        </el-checkbox-group>
                        <loadingState v-if="loadState == 'first_loading'||chatList.list.length > 0"
                            :state='loadState' />
                    </div>
                    <div class="empty_data_left" v-if="!chatList.list.length>0">
                        <img src="@/assets/goods/empty_data.png" alt="">
                        <p>{{L['暂无数据～']}}</p>
                    </div>
                </el-scrollbar>
                <!-- <div v-if="tabIndex==2">
                    <div class="list_item" v-for="(item,index) in [1,2,3,4,5]" :key="index" id="queuing">
                        <img src="@/assets/service/avatar.png" alt="">
                        <div class="list_text">
                            <p class="list_item_title">aawdawd{{item}}</p>
                            <p class="msg_new">awdawddadawdawdawdawdawdawdawdawdawd</p>
                        </div>
                        <div class="delete_icon">
                            <img src="@/assets/service/delete.png" alt="">
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, getCurrentInstance, reactive, onMounted, inject } from 'vue';
    import { useStore } from "vuex";
    import loadingState from '@/components/loadingState'
    import { getQueryParam, formatTime } from '@/utils/common';
    import { ElMessage } from 'element-plus';

    export default {
        name: 'chatLeftList',
        components: {
            loadingState
        },
        emits: ['switchMember'],
        props: ['connectBaseData'],
        beforeCreate() {
            this.sockets.subscribe('contact_change', (e) => {
                console.log(e);
                this.changeContact(e); // 切换联系人
            });
            this.sockets.subscribe('unread_num_change', (e) => {
                let tmp_data = this.chatList.list.filter(item => item.memberId == e.memberId)
                if (tmp_data.length == 1) {
                    document.title = '有鱼生活 - 客服助手'
                    tmp_data[0].receiveMsgNumber = e.unreadNum;
                }
            });
            this.sockets.subscribe('receive_num_change', (e) => {
                console.log('receive_num_change: ', e);
                this.countChat = e.count;
            });
        },
        setup(props, { emit }) {
            const { proxy } = getCurrentInstance();
            const connectData = reactive(props.connectBaseData);
            const store = useStore();
            const tabIndex = ref(1);
            const storeInfo = reactive({ data: store.state.storeInfo });
            const pageSize = 20;
            const current = ref(1);
            const curMemberId = ref(0);//当前聊天会员的id
            const minMsgId = ref('');//当前消息的最小id
            const chatList = reactive({ list: [] });//最近联系人列表
            const loadState = ref('')
            const changeTab = (index) => {
                tabIndex.value = index
            }
            const adminInfo = inject('adminInfo');
            const workState = inject('workState');
            const countChat = ref(0); // 消息接入数

            const contactIds = ref([]); // 批量操作选中联系人IDS
            const isIndeterminate = ref(false); // 全选显示状态
            const checkAll = ref(false); // 全选

            const adminLogo = require('@/assets/adminLogo.png')
            const isScroll = ref(false)
            const hasMore = ref(true)
            const leaveMessage = ref(true);
            const clientHeight = ref(0)
            const L = proxy.$getCurLanguage()
            const adminName = localStorage.adminName
            //获取最近联系人
            const getChatList = () => {
                const params = {};
                params.pageSize = pageSize;
                params.current = current.value;

                if (minMsgId.value) {
                    params.msgId = minMsgId.value;
                }
                proxy.$get('im-web/v3/helpdesk/admin/chat/list', params).then(res => {
                    if (res.state == 200) {
                        if (minMsgId.value) {
                            let tempList = [];
                            res.data.forEach((item) => {
                                const isExist = chatList.list.some(itemA => itemA.memberId === item.memberId);
                                if (!isExist) {
                                    tempList.push(item);
                                }
                            });
                            chatList.list = chatList.list.concat(tempList);
                        } else {
                            chatList.list = res.data;
                        }
                        formatMsgContent()
                        if (chatList.list.length > 0) {
                            minMsgId.value = chatList.list[chatList.list.length - 1].msgId
                            if (proxy.$socket.connected) {
                                const memberId = getQueryParam('memberId');
                                if (!memberId) {
                                    console.log('admin_change_room111: ', curMemberId.value);
                                    proxy.$socket.emit("admin_change_room", { memberId: curMemberId.value, ...connectData }, () => {
                                        if (isScroll.value == false) {
                                            console.log('switchMember===222', { memberId: curMemberId.value, memberName: chatList.list[0].memberName, memberRemarks: chatList.list[0].memberRemarks });
                                            emit('switchMember', { memberId: curMemberId.value, memberName: chatList.list[0].memberName, memberRemarks: chatList.list[0].memberRemarks });
                                        }
                                    });
                                }
                            }
                        } else {
                            curMemberId.value = 0;
                            emit('curChatMemInfo', {})
                        }
                        // 会员留言
                        if (proxy.$socket.connected && leaveMessage.value) {
                            const memberId = getQueryParam('memberId');
                            const memberName = getQueryParam('memberName');
                            const memberAvatar = 'https://youyu-image-test.oss-cn-shenzhen.aliyuncs.com/channel1images/admin/setting/OVPx1rrFYJZGfuumhAD.png?x-oss-process=image/resize,w_0,h_100';
                            if (memberId) {
                                console.log('留言: memberId=', memberId, ' memberName=', memberName);
                                switchMember(memberId, memberName);
                                changeContact({memberId, memberName, memberAvatar});
                                leaveMessage.value = false;
                            }
                        }
                        hasMore.value = res.data.length < 10 ? false : true;
                        if (hasMore.value) {
                            loadState.value = 'allow_loading_more';
                        } else {
                            loadState.value = 'no_more_data';
                        }
                    }
                })
            }
            //格式化聊天内容，方便列表展示
            const formatMsgContent = () => {
                let reg = /<img [^>]*src=['"]([^'"]+)[^>]*>/g

                let reg4 = /(<\/?div.*?>)|(<\/?br.*?>)|(<\/?span.*?>)/g;
                if (chatList.list.length > 0) {
                    chatList.list.map(item => {
                        if (typeof item.msgContent == 'string') {
                            item.msgContent = JSON.parse(item.msgContent)
                        }
                        //1.text(文本) 2.img(图片) 3.goods(商品) 4.order(订单)用户
                        if (item.msgType == 1) {

                            if (reg.test(item.msgContent.content)) {
                                item.msgContent.content = item.msgContent.content.replace(reg, L['[表情]'])
                                item.showContent = item.msgContent.content
                            } else {
                                item.showContent = item.msgContent.content;
                            }

                            if (reg4.test(item.msgContent.content)) {
                                item.msgContent.content = item.msgContent.content.replace(reg4, '')
                                item.showContent = item.msgContent.content
                            } else {
                                item.showContent = item.msgContent.content;
                            }

                        } else if (item.msgType == 2) {
                            item.showContent = L['[图片]'];
                        } else if (item.msgType == 3) {
                            item.showContent = L['[商品]'];
                        } else if (item.msgType == 4) {
                            item.showContent = L['[订单]'];
                        }
                    })
                }
            }

            // 切换联系人
            const changeContact = (e, from) => {
                if (chatList.list.length == 0) {
                    chatList.list.push(e);
                    console.log('changeContact===空列表，来人: ', e.memberId);
                    switchMember(e.memberId, e.memberName, e.memberRemarks);
                    // curMemberId.value = e.memberId;
                    // proxy.$socket.emit("admin_change_room", { memberId: e.memberId, ...connectData }, () => {
                    //     emitParentData({ memberId: e.memberId, memberName: e.memberName, memberRemarks: e.memberRemarks, action: 'admin_change_room' });
                    // });
                } else {
                    let cur_index = chatList.list.findIndex(item => item.memberId == e.memberId);
                    let no_top_index = chatList.list.findIndex(item => item.isTopping != 1);
                    // console.log('cur_index: ', cur_index, ' no_top_index:', no_top_index);
                    if (cur_index == -1) {
                        chatList.list.splice(no_top_index, 0, e);
                    } else if (cur_index != 0) {
                        const oldIsTopping = chatList.list[cur_index].isTopping == 1;
                        const isTopping = from == 'toggleTop' ? e.isTopping == 1 : oldIsTopping;
                        chatList.list.splice(cur_index, 1);
                        if (isTopping) {
                            e.isTopping = 1;
                            chatList.list.unshift(e);
                        } else {
                            if (no_top_index == -1) {
                                chatList.list.push(e);
                            } else {
                                // 原来置顶,现在不置顶,减去自己索引位置
                                if (from == 'toggleTop' && e.isTopping == 0 && oldIsTopping) {
                                    no_top_index = no_top_index - 1;
                                }
                                chatList.list.splice(no_top_index, 0, e);
                            }
                        }
                    } else {
                        const oldIsTopping = chatList.list[cur_index].isTopping == 1;
                        const isTopping = from == 'toggleTop' ? e.isTopping == 1 : oldIsTopping;
                        if (isTopping) {
                            e.isTopping = 1;
                            chatList.list[cur_index] = e;
                        } else {
                            chatList.list.splice(cur_index, 1);
                            if (no_top_index == -1) {
                                chatList.list.push(e);
                            } else {
                                // 原来置顶,现在不置顶,减去自己索引位置
                                if (from == 'toggleTop' && e.isTopping == 0 && oldIsTopping) {
                                    no_top_index = no_top_index - 1;
                                }
                                chatList.list.splice(no_top_index, 0, e);
                            }
                        }
                        if (curMemberId.value == e.memberId ) {
                            emitParentData({ memberId: e.memberId, memberName: e.memberName, memberRemarks: e.memberRemarks, action: 'update' });
                        }
                    }


                    if (curMemberId.value != e.memberId) {
                        if ((localStorage.msgVoiceSetting === true || localStorage.msgVoiceSetting === 'true')) {
                            play();
                        }
                    }
                }
                formatMsgContent();
            }

            //切换会员事件
            const switchMember = (memberId, memberName, memberRemarks) => {
                let oldMemberId = curMemberId.value;
                curMemberId.value = memberId;
                console.log('switchMember===111', { memberId, memberName, memberRemarks });
                emit('switchMember', { memberId, memberName, memberRemarks });
                console.log('admin_change_room===111: ', { memberId, oldMemberId, ...connectData });
                proxy.$socket.emit("admin_change_room", { memberId, oldMemberId, ...connectData });
            }

            // 置顶联系人
            const handleToggleTop = (contact, event) => {
                event && event.stopPropagation();
                let params = {
                    memberId: contact.memberId
                }
                proxy.$post('im-web/v3/helpdesk/admin/topping/operate', params).then(res => {
                    if (res.state === 200) {
                        // contact.isTopping = res.data;
                        // current.value = 1;
                        // minMsgId.value = '';
                        // getChatList();
                        const newContact = {
                            ...contact,
                            isTopping: res.data,
                        }
                        changeContact(newContact, 'toggleTop'); // 切换联系人
                    } else {
                        ElMessage.error(res.msg);
                    }
                });
            }

            // 关闭与会员的聊天事件
            const closeChatMember = (memberId) => {
                proxy.$socket.emit("admin_remove_contact", { memberId, ...connectData });
                chatList.list = chatList.list.filter(item => item.memberId != memberId);
                if (curMemberId.value == memberId) {
                    if (chatList.list.length > 0) {
                        //该会员与当前聊天的会员是同一个，需要先移除会员再切换
                        switchMember(chatList.list[0].memberId, chatList.list[0].memberName, chatList.list[0].memberRemarks);
                    } else {
                        emit('curChatMemInfo', {})
                    }
                }
            }

            // 移除已选择的联系人-多选
            const closeChatMemberAll = () => {
                proxy.$socket.emit("admin_remove_contact", { memberId: contactIds.value.join(), ...connectData });
                chatList.list = chatList.list.filter(item => !contactIds.value.includes(item.memberId));
                isIndeterminate.value = false;
                if (contactIds.value.includes(curMemberId.value)) {
                    if (chatList.list.length > 0) {
                        //该会员与当前聊天的会员是同一个，需要先移除会员再切换
                        switchMember(chatList.list[0].memberId, chatList.list[0].memberName, chatList.list[0].memberRemarks);
                    } else {
                        emit('curChatMemInfo', {})
                    }
                }
                contactIds.value = [];
            }

            // 更新联系人备注
            const handleChangeRemark = (memberId, memberRemarks) => {
                console.log('memberId: ', memberId, ' =', memberRemarks);
                chatList.list.some((item) => {
                    if (item.memberId === memberId) {
                        item.memberRemarks = memberRemarks;
                        return true;
                    }
                });
            }

            // 切换客服在线状态
            const handleChangeStatus = () => {
                console.log('workState=', workState.value);
                let params = {
                    adminId: connectData.userId,
                    state: workState.value
                }
                proxy.$post('im-web/v3/customer/service/workstate', params).then(res => {
                    ElMessage.success(res.msg);
                });
            }

            // 向下滚动至底部加载数据
            const load = () => {
                if (hasMore.value) {
                    isScroll.value = true
                    getChatList()
                }
            }

            // 消息提示音
            const play = () => {
                let audioElement = document.createElement('audio');
                let voice = require('@/assets/voice/msg.mp3')
                audioElement.setAttribute('src', voice);
                audioElement.setAttribute('autoplay', 'autoplay');
            }

            onMounted(() => {
                clientHeight.value = document.body.clientHeight || document.documentElement.clientHeight
                getChatList();
            })

            const emitParentData = (data) => {
                console.log('emitParentData: ');
                // curMemberId.value = data.memberId;
                emit('switchMember', { memberId: data.memberId, memberName: data.memberName, memberRemarks: data.memberRemarks, action: data.action });
            }

            // 批量操作选择
            const handleCheckbox = (event) => {
                event && event.stopPropagation();
            }
            const handleCheckChange = (value) => {
                checkAll.value = value.length === chatList.list.length;
                isIndeterminate.value = value.length > 0 && value.length < chatList.list.length;
            }
            const handleCheckAllChange = (value) => {
                isIndeterminate.value = false;
                if (value) {
                    contactIds.value = chatList.list.map(item => item.memberId);
                } else {
                    contactIds.value = [];
                }
            }

            return {
                checkAll,
                isIndeterminate,
                handleCheckAllChange,
                handleCheckChange,
                handleCheckbox,
                contactIds,
                handleToggleTop,
                countChat,
                formatTime,
                handleChangeRemark,
                handleChangeStatus,
                workState,
                changeContact,
                tabIndex,
                changeTab,
                chatList,
                storeInfo,
                formatMsgContent,
                curMemberId,
                switchMember,
                closeChatMember,
                closeChatMemberAll,
                load,
                loadState,
                emitParentData,
                connectData,
                clientHeight,
                L,
                adminInfo,
                adminName,
                adminLogo,
                play
            }
        }
    }
</script>

<style lang="scss" scoped>
    .sld_chat_left {
        display: flex;
        flex-direction: column;
        width: 300px;
        flex-shrink: 0;
        transition: all .1s;
        overflow: hidden;

        &.hide {
            width: 0;
            margin-left: 0;
        }
        @media screen and (max-width: 1024px) {
            width: 200px;
        }
        @media screen and (max-width: 640px) {
            width: 100px;
        }

        .top {
            .info {
                display: flex;
                margin-top: 11px;

                &>img {
                    width: 44px;
                    height: 44px;
                    margin-left: 20px;
                    @media screen and (max-width: 1024px) {
                        margin-left: 10px;
                    }
                    @media screen and (max-width: 640px) {
                        margin-left: 5px;
                        width: 24px;
                        height: 24px;
                    }
                }

                .text {
                    margin-left: 12px;

                    p {
                        height: 23px;
                        display: flex;
                        align-items: center;

                        span {
                            display: inline-block;
                            height: 15px;
                            line-height: 15px;
                            margin-right: 10px;
                        }

                        &:nth-child(2) {

                            color: #51A4FD;
                        }

                        img {
                            width: 16px;
                            height: 16px;
                        }
                        
                    }

                    .color-blue {
                        color: #0E6FD7;
                    }
                    .color-red {
                        color: #ff0000;
                    }
                    .color-yellow {
                        color: #ff9421;
                    }
                }
            }
        }

        .batch-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5px;

            @media screen and (max-width: 640px) {
                display: none;
            }

            .btn-delete-all {
                margin-right: 10px;
                color: #ff0000;
                cursor: pointer;
            }
        }

        .list_wrap {
            display: flex;
            padding-top: 10px;
            width: 100%;
            height: calc(100% - 57px);

            .tab {
                height: 46px;

                span {
                    display: inline-block;
                    text-align: center;
                    height: 52px;
                    padding: 17px 35px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    border-bottom: 1px solid #f7f7f7;
                    cursor: pointer;

                    i {
                        font-style: normal;
                    }
                }

                .tabChosen {
                    border-bottom: 2px solid #0871FF;

                    i {
                        width: 95px;
                        height: 17px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #0B72F2;
                    }
                }
            }

            .list_con {
                width: 100%;
                height: 100%;

                .list_item {
                    padding: 15px 17px;
                    width: 97%;
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid #f7f7f7;
                    position: relative;
                    @media screen and (max-width: 1024px) {
                        padding: 10px 10px;
                    }
                    @media screen and (max-width: 640px) {
                        padding: 10px 5px;
                    }

                    &>img {
                        width: 40px;
                        height: 40px;
                        @media screen and (max-width: 640px) {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .msg_badge {
                        display: inline-block;
                        position: absolute;
                        top: 5px;
                        left: 42px;
                        font-style: normal;
                        margin-right: 6px;
                        padding: 2px 5px;
                        background-color: #e2231a;
                        color: #fff;
                        font-size: 12px;
                        border-radius: 9px;
                        transform: scale(0.9);

                        @media screen and (max-width: 640px) {
                            top: 1px;
                            left: 15px;
                        }
                    }

                    .checkbox {
                        position: absolute;
                        top: 26px;
                        left: 0px;

                        @media screen and (max-width: 640px) {
                            display: none;
                        }
                    }


                    &:hover {
                        background-color: #F5F5F5;

                        .delete_icon {
                            @media screen and (max-width: 640px) {
                                display: none;
                            }
                            .del_icon {
                                display: block;
                            }

                            .btn-top {
                                display: block;
                            }

                        }
                    }

                    .list_text {
                        margin-left: 10px;

                        .list_item_title {
                            display: flex;
                            justify-content: space-between;
                            height: 15px;
                            line-height: 15px;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            .msg_time {
                                font-size: 12px;
                                @media screen and (max-width: 640px) {
                                    display: none;
                                }
                            }
                        }

                        .msg_new {
                            display: flex;
                            justify-content: space-between;
                            width: 175px;
                            margin-top: 9px;
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            @media screen and (max-width: 1024px) {
                                width: 100px;
                            }
                            @media screen and (max-width: 640px) {
                                display: none;
                            }

                            .msg_content {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                width: 135px;
                            }
                        }
                    }

                    .delete_icon {
                        position: absolute;
                        right: 17px;
                        display: flex;
                        @media screen and (max-width: 640px) {
                            right: 7px;
                        }

                        i {
                            font-style: normal;
                            margin-right: 6px;
                            display: inline-block;
                            padding: 2px 5px;
                            background-color: #e2231a;
                            color: #fff;
                            font-size: 12px;
                            border-radius: 9px;
                            transform: scale(0.9)
                        }

                        .del_icon {
                            position: absolute;
                            top: 10px;
                            right: 0;
                            display: none;
                            cursor: pointer;
                        }

                        .btn-top {
                            position: absolute;
                            top: -25px;
                            right: 0;
                            display: none;
                            width: 20px;
                            height: 20px;
                            cursor: pointer;

                            &.show {
                                display: block;
                                @media screen and (max-width: 640px) {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .empty_data_left {
        height: 300px;
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            width: 80px;
        }

        p {
            margin-top: 15px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #D5D5D5;
        }
    }
</style>