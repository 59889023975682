<template>
    <div class="contextmenu-module" :style="{ top: `${config.y}px`, left: `${config.x}px` }" :hidden="hidden">
        <ul class="item-list">
            <li class="item" v-for="(item, index) in config.items" :key="index" @click="($event) => onClick($event, item)">{{ item.label }}</li>
        </ul>
    </div>
</template>

<script>
import { ref, reactive, onMounted, onUnMounted } from 'vue';
export default {
    name: "contextmenu",
    setup(props, { emit }) {
        const hidden = ref(true);

        const config = reactive({
            y: 0,
            x: 0,
            items: []
        });

        const onShow = (data) => {
            Object.assign(config, data);
            hidden.value = false;
        }

        const onClick = (event, item) => {
            item.onClick();
        }

        const documentClick = () => {
            // console.log('document click');
            hidden.value = true;
        }

        onMounted(() => {
            document.removeEventListener('click', documentClick);
            document.addEventListener('click', documentClick);
        });

        // onUnMounted(() => {
        //     document.removeEventListener('click', documentClick);
        // });

        return {
            config,
            hidden,
            onShow,
            onClick
        }
    }
}
</script>

<style lang='scss' scoped>
.contextmenu-module {
    position: fixed;
    border-radius: 5px;
    padding: 5px 0;
    background-color: #fff;
    box-shadow: 0 10px 40px 10px rgba(0, 0, 0, .1);
    ;
    z-index: 99999;

    .item-list {
        .item {
            display: flex;
            justify-content: center;
            padding: 10px;
            min-width: 80px;
            &:hover {
                background-color: #f1f1f1;
            }
        }
    }
}
</style>